"use-strict";

import * as Config from "./config.js";
import * as Apps from "./app.js";
import * as LoadingScreen from "./loader.js";

const baseUrl = Config.configSite().baseUrl;
const refURL = Config.configSite().refURL;
const Highcharts = require("highcharts");
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

export const containerMaps = document.getElementById("container-maps");
export const btnTogglerMaps = document.getElementById("maps-toggler");

const dataWilayah = (urlParam) => {
  return fetch(`${baseUrl}${refURL}/${urlParam}.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const mapsProv = () => {
  LoadingScreen.beLoading(true);
  let dataMaps = [];
  dataWilayah("pkwkp").then((dataProv) => {
    for (let key in dataProv) {
      dataMaps.push({
        code: key,
        value: 1,
      });
    }
  });

  fetch("geo/Propinsi.geojson")
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((geojson) => {
      const options = {
        title: {
          text: "Pemilihan Gubernur dan Wakil Gubernur",
        },
        subtitle: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        mapNavigation: {
          enabled: false,
          enableMouseWheelZoom: false,
          enableDoubleClickZoom: false,
          enableDoubleClickZoomTo: false,
          enableTouchZoom: false,
          buttonOptions: {
            verticalAlign: "bottom",
          },
        },
        tooltip: {
          enabled: false,
        },
        colorAxis: {
          min: 0,
          stops: [
            [0, "#EFEFFF"],
            [0.5, Highcharts.getOptions().colors[1]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[1])
                .brighten(-0.5)
                .get(),
            ],
          ],
        },
        tooltip: {
          enabled: true,
          pointFormat: "",
          footerFormat: "",
          formatter: function () {
            return `<b>${this.point.properties.Propinsi}</b>`;
          },
          shared: true,
        },
        responsive: true,
        series: [
          {
            data: dataMaps,
            mapData: geojson,
            name: "Jumlah Suara",
            joinBy: ["kode", "code"],
            states: {
              hover: {
                color: "#BADA55",
              },
            },
            point: {
              events: {
                click: function () {
                  mapsProvClick(this.kode);
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
          },
        ],
      };
      const graph = Highcharts.mapChart("map-prov", options);
      graph.update(options);
    })
    .catch((error) => console.log(error));
};

const mapsProvClick = (datas) => {
  Apps.addNone(containerMaps);
  let locDatas = datas.toString();
  Apps.loadProv();
  Apps.provinsiOption.setChoiceByValue(locDatas);
  Apps.removesNone(Apps.kabkotaContainer);
  Apps.loadKabKota(locDatas);
  Apps.appendCrumb(
    "provinsi",
    Apps.provinsiOption.getValue().label,
    Apps.provinsiOption.getValue(true)
  );
  let catPem = null;
  Apps.firstDropdown.value === "pkwkp"
    ? (catPem = "pkwkp")
    : (catPem = "pkwkk");
  Apps.router.navigate(`/${catPem}/tungsura/${datas}`);
};

export const mapsKabKota = () => {
  let dataMaps = [];
  dataWilayah("pkwkk").then((dataKabKota) => {
    for (let key in dataKabKota) {
      for (let index in dataKabKota[key]) {
        dataMaps.push({
          code: index,
          value: 1,
        });
      }
    }
  });

  fetch("geo/Kabupaten.geojson")
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((geojson) => {
      const options = {
        title: {
          text: "Pemilihan Bupati/Walikota dan Wakil Bupati/Walikota",
        },
        subtitle: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        mapNavigation: {
          enabled: false,
          enableMouseWheelZoom: false,
          enableDoubleClickZoom: false,
          enableDoubleClickZoomTo: false,
          enableTouchZoom: false,
          buttonOptions: {
            verticalAlign: "bottom",
          },
        },
        tooltip: {
          enabled: false,
        },
        colorAxis: {
          min: 0,
          stops: [
            [0, "#EFEFFF"],
            [0.5, Highcharts.getOptions().colors[2]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[2])
                .brighten(-0.5)
                .get(),
            ],
          ],
        },
        tooltip: {
          enabled: true,
          pointFormat: "",
          footerFormat: "",
          formatter: function () {
            return `<b>${this.point.properties.KABKOT}</b>`;
          },
          shared: true,
        },
        responsive: true,
        series: [
          {
            data: dataMaps,
            mapData: geojson,
            name: "Jumlah Suara",
            joinBy: ["IDKAB", "code"],
            states: {
              hover: {
                color: "#BADA55",
              },
            },
            point: {
              events: {
                click: function () {
                  mapsKabsClick(this.IDKAB);
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
          },
        ],
      };
      const graph = Highcharts.mapChart("map-kabkota", options);
      graph.update(options);
      LoadingScreen.beLoading(false);
    })
    .catch((error) => console.log(error));
};

const mapsKabsClick = (datas) => {
  Apps.addNone(containerMaps);
  let sliceN = datas.toString().slice(0, 2);
  Apps.loadProv();
  Apps.loadKabKota(sliceN);
  Apps.loadKec(sliceN, datas);
  Apps.provinsiOption.setChoiceByValue(sliceN);
  Apps.removesNone(Apps.kabkotaContainer);
  Apps.removesNone(Apps.detailFilter);
  Apps.removesNone(Apps.kecamatanContainer);
  Apps.kabkotaOption.setChoiceByValue(datas.toString());
  Apps.appendCrumb(
    "provinsi",
    Apps.provinsiOption.getValue().label,
    Apps.provinsiOption.getValue(true)
  );
  Apps.appendCrumb(
    "kabkota",
    Apps.kabkotaOption.getValue().label,
    Apps.kabkotaOption.getValue(true)
  );
};

btnTogglerMaps.style.backgroundColor = "#f3f3f3";
btnTogglerMaps.style.color = "rgb(155,27,27)";

btnTogglerMaps.addEventListener("click", (e) => {
  let btnText = e.target.innerText.toString().toLowerCase().replace(/\s/g, "");
  btnText === "sembunyikanpeta"
    ? ((btnTogglerMaps.innerText = "Tampilkan Peta"),
      Apps.addNone(containerMaps),
      (btnTogglerMaps.style.backgroundColor = ""),
      (btnTogglerMaps.style.borderColor = "rgba(255,255,255,.3)"),
      (btnTogglerMaps.style.color = "rgba(255,255,255,.75)"))
    : ((btnTogglerMaps.innerText = "Sembunyikan Peta"),
      Apps.removesNone(containerMaps),
      (btnTogglerMaps.style.backgroundColor = "#f3f3f3"),
      (btnTogglerMaps.style.color = "rgb(155,27,27)"));
});
