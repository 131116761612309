"use strict";

import "../../vendors/bootstrap/css/bootstrap.min.css";
import "../../vendors/choices/styles/choices.min.css";
import "../../vendors/simplelightbox-master/code/simple-lightbox.css";
import "../../assets/css/main.css";

import * as Config from "./config.js";
import * as Calculate from "./calculate.js";
import * as MapsV from "./maps.js";
import * as Plugins from "./plugins.js";
import * as Analytic from "./analytic.js";
import * as LoadingScreen from "./loader.js";
import Choices from "../../vendors/choices/scripts/choices.js";
import "regenerator-runtime/runtime";
import "../../vendors/bootstrap/js/bootstrap.bundle.js";

// Initialize element
const screenDimension = Plugins.getViewport();
const myCollapsible = document.getElementById("filterTab");
const theContainer = document.getElementById("main-container");
const breadNav = document.getElementById("breadNav");
const tombolFilter = document.getElementById("filter-toggler");
const noticeData = document.getElementById("noticeNoData");
const Navigo = require("navigo");
const root = null;
const useHash = true;
const hash = "#";
export const router = new Navigo(root, useHash, hash);

// DOM event load func
document.addEventListener("DOMContentLoaded", function (e) {
  // Set Conf
  document.title = Config.configSite().title;

  myCollapsible.addEventListener("hide.bs.collapse", function () {
    theContainer.classList.remove("collapsed");
  });
  myCollapsible.addEventListener("show.bs.collapse", function () {
    theContainer.classList.add("collapsed");
  });

  // Display adjustment
  if (screenDimension[0] <= 769) {
    addNone(MapsV.containerMaps);
    addNone(MapsV.btnTogglerMaps);
  } else if (screenDimension[0] >= 1024) {
    if (tombolFilter.children[0].textContent == "Sembunyikan Filter") {
      tombolFilter.children[0].textContent = "Tampilkan Filter";
    } else {
      tombolFilter.children[0].textContent = "Sembunyikan Filter";
    }
    document.getElementById("filter-toggler").classList.remove("collapsed");
    document.getElementById("main-container").classList.add("collapsed");
    document.getElementById("filter-tab").classList.add("show");
    MapsV.mapsProv();
    MapsV.mapsKabKota();
  }

  let mapProv = document.getElementById("map-prov"),
    mapKabKota = document.getElementById("map-kabkota");

  //Load func
  Analytic.inisiateAnalytic(null);
  loadRouter();
  pemilikanKet(null);
  loadProv();
  if (router.lastRouteResolved().url.split("/")[1] === "pkwkk") {
    firstOption.setChoiceByValue("pkwkk");
    toggleMaps(mapProv, mapKabKota);
    addNone(document.getElementById("chartHome"));
  } else {
    toggleMaps(mapKabKota, mapProv);
    firstOption.setChoiceByValue("pkwkp");
    initLanding();
  }
});

const baseUrl = Config.configSite().baseUrl;
const refURL = Config.configSite().refURL;

// Initialize dropdowns
export const firstDropdown = document.getElementById("walkotgov");
const firstOption = new Choices(firstDropdown, {
  placeholderValue: firstDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: false,
});

export const provinsiDropdown = document.getElementById("provinsi");
export const provinsiOption = new Choices(provinsiDropdown, {
  placeholderValue: provinsiDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: true,
});

export const jenisSuaraContainer = document.getElementById("containerTypes");
export const jenisSuaraDropdown = document.getElementById("types");
export const jenisSuaraOption = new Choices(jenisSuaraDropdown, {
  placeholderValue: jenisSuaraDropdown.getAttribute(
    "data-dropdown-placeholder"
  ),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: false,
});

export const kabkotaContainer = document.getElementById("containerKabkota");
export const kabkotaDropdown = document.getElementById("kabkota");
export const kabkotaOption = new Choices(kabkotaDropdown, {
  placeholderValue: kabkotaDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: true,
});

export const detailFilter = document.getElementById("detailFilters");
export const kecamatanContainer = document.getElementById("containerKecamatan");
export const kecamatanDropdown = document.getElementById("kecamatan");
export const kecamatanOption = new Choices(kecamatanDropdown, {
  placeholderValue: kecamatanDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: true,
});

export const desaContainer = document.getElementById("containerDesa");
export const desaDropdown = document.getElementById("desa");
export const desaOption = new Choices(desaDropdown, {
  placeholderValue: desaDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: true,
});

export const tpsContainer = document.getElementById("containerTps");
const tpsDropdown = document.getElementById("tps");
export const tpsOption = new Choices(tpsDropdown, {
  placeholderValue: tpsDropdown.getAttribute("data-dropdown-placeholder"),
  searchPlaceholderValue: "Ketik kata kunci di sini...",
  itemSelectText: "",
  maxItemCount: 5,
  shouldSort: false,
  searchEnabled: true,
});

const breadProv = document.getElementById("breadProv"),
  breadKab = document.getElementById("breadKab"),
  breadKec = document.getElementById("breadKec"),
  breadKel = document.getElementById("breadKel"),
  breadTPS = document.getElementById("breadTPS");

tombolFilter.addEventListener("click", function () {
  if (tombolFilter.children[0].textContent == "Sembunyikan Filter") {
    tombolFilter.children[0].textContent = "Tampilkan Filter";
    document.getElementById("main-container").classList.remove("col-bsize");
  } else {
    tombolFilter.children[0].textContent = "Sembunyikan Filter";
    if (kabkotaDropdown.value !== "")
      document.getElementById("main-container").classList.add("col-bsize");
  }
});

// Gubernur / Bupati/Walikota dropdown change
firstDropdown.addEventListener("change", function () {
  document
    .querySelector("#main-container.collapsed")
    .classList.remove("col-bsize");
  addNone(rekapLabel);
  addNone(Calculate.detailPenetapan);
  if (MapsV.btnTogglerMaps.innerText == "Tampilkan Peta") {
    MapsV.btnTogglerMaps.dispatchEvent(new Event("click"));
  }
  if (this.value == "pkwkp") {
    removesNone(document.getElementById("chartHome")),
      removeAllComponent(document.getElementById("pieChartContainer")),
      initLanding();
  } else {
    addNone(document.getElementById("chartHome")),
      removeAllComponent(document.getElementById("pieChartContainer"));
  }
  pemilikanKet(this.value);
  provinsiOption.clearStore();
  provinsiOption.setChoices(
    [{ value: "", label: "Pilih Provinsi", placeholder: true }],
    "value",
    "label",
    false
  );
  provinsiOption.setChoiceByValue("");
  kabkotaOption.clearStore();
  kabkotaOption.setChoices(
    [{ value: "", label: "Pilih Kabupaten/Kota", placeholder: true }],
    "value",
    "label",
    false
  );
  kabkotaOption.setChoiceByValue("");
  addNone(Calculate.chartData);
  addNone(Calculate.tableData);

  if (screenDimension[0] <= 769) {
    addNone(MapsV.containerMaps);
    addNone(MapsV.btnTogglerMaps);
  } else if (screenDimension[0] >= 1024) {
    removesNone(MapsV.containerMaps);
  }

  loadProv();
  router.navigate(`/${this.value}`);
});

const pemilikanKet = (params) => {
  let mapProv = document.getElementById("map-prov"),
    mapKabKota = document.getElementById("map-kabkota");
  addNone(detailFilter);
  addNone(jenisSuaraContainer);
  addNone(kabkotaContainer);
  provinsiOption.setChoiceByValue("");

  if (screenDimension[0] <= 769) {
    addNone(MapsV.containerMaps);
    addNone(MapsV.btnTogglerMaps);
  } else if (screenDimension[0] >= 1024) {
    if (params == "pkwkk") {
      toggleMaps(mapProv, mapKabKota);
    } else {
      toggleMaps(mapKabKota, mapProv);
    }
  }

  removeAllComponent(breadProv);
  breadProv.classList.add("d-none");
  removeAllComponent(breadKab);
  breadKab.classList.add("d-none");
  removeAllComponent(breadKec);
  breadKec.classList.add("d-none");
  removeAllComponent(breadKel);
  breadKel.classList.add("d-none");
  removeAllComponent(breadTPS);
  breadTPS.classList.add("d-none");
  breadNav.classList.add("d-none");
};

export const checkOrdering = () => {
  if (firstDropdown.value == "pkwkp") {
    removesNone(jenisSuaraContainer);
    if (jenisSuaraContainer.classList.contains("order-4"))
      jenisSuaraContainer.classList.replace("order-4", "order-3");
    if (kabkotaContainer.classList.contains("order-3"))
      kabkotaContainer.classList.contains("order-3", "order-4");
  } else {
    removesNone(jenisSuaraContainer);
    jenisSuaraContainer.classList.add("order-4");
    kabkotaContainer.classList.add("order-3");
  }
};

export const removeAllComponent = (component) => {
  while (component.firstChild && component.removeChild(component.firstChild));
};

const toggleMaps = (map1, map2) => {
  map1.classList.add("d-none");
  map2.classList.remove("d-none");
  map2.classList.add("zerowidth");
  setTimeout(function () {
    map2.classList.remove("zerowidth");
  }, 250);
};

export const appendCrumb = (params, value, nodes) => {
  if (params == "provinsi") {
    breadNav.classList.remove("d-none");
    breadProv.classList.remove("d-none");
    let getLabel = value;
    let listHref = document.createElement("a");
    listHref.setAttribute("class", "crumbsNode");
    listHref.setAttribute("data-whatever", nodes);
    listHref.style.cursor = "pointer";
    removeAllComponent(breadProv);
    removeAllComponent(breadKab);
    breadKab.classList.add("d-none");
    removeAllComponent(breadKec);
    breadKec.classList.add("d-none");
    removeAllComponent(breadKel);
    breadKel.classList.add("d-none");
    removeAllComponent(breadTPS);
    breadTPS.classList.add("d-none");
    listHref.appendChild(document.createTextNode(getLabel));
    breadProv.appendChild(listHref);
  } else if (params == "kabkota") {
    breadKab.classList.remove("d-none");
    let getLabel = value;
    let listHref = document.createElement("a");
    listHref.setAttribute("class", "crumbsNode");
    listHref.setAttribute("data-whatever", nodes);
    listHref.style.cursor = "pointer";
    removeAllComponent(breadKab);
    removeAllComponent(breadKec);
    breadKec.classList.add("d-none");
    removeAllComponent(breadKel);
    breadKel.classList.add("d-none");
    removeAllComponent(breadTPS);
    breadTPS.classList.add("d-none");
    listHref.appendChild(document.createTextNode(getLabel));
    breadKab.appendChild(listHref);
  } else if (params == "kecamatan") {
    breadKec.classList.remove("d-none");
    let getLabel = value;
    let listHref = document.createElement("a");
    listHref.setAttribute("class", "crumbsNode");
    listHref.setAttribute("data-whatever", nodes);
    listHref.style.cursor = "pointer";
    removeAllComponent(breadKec);
    removeAllComponent(breadKel);
    breadKel.classList.add("d-none");
    removeAllComponent(breadTPS);
    breadTPS.classList.add("d-none");
    listHref.appendChild(document.createTextNode(getLabel));
    breadKec.appendChild(listHref);
  } else if (params == "kelurahan") {
    breadKel.classList.remove("d-none");
    let getLabel = value;
    let listHref = document.createElement("a");
    listHref.setAttribute("class", "crumbsNode");
    listHref.setAttribute("data-whatever", nodes);
    listHref.style.cursor = "pointer";
    removeAllComponent(breadKel);
    removeAllComponent(breadTPS);
    breadTPS.classList.add("d-none");
    listHref.appendChild(document.createTextNode(getLabel));
    breadKel.appendChild(listHref);
  } else if (params == "tps") {
    breadTPS.classList.remove("d-none");
    let getLabel = value;
    let listHref = document.createElement("a");
    listHref.setAttribute("class", "crumbsNode");
    listHref.setAttribute("data-whatever", nodes);
    listHref.style.cursor = "pointer";
    removeAllComponent(breadTPS);
    listHref.appendChild(document.createTextNode(getLabel));
    breadTPS.appendChild(listHref);
  }
};

tpsDropdown.addEventListener("change", function () {
  if (this.value == "") {
    let catPem = null;
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(
      `/${catPem}/${jenisSuaraDropdown.value}/${desaDropdown.value}`
    );
    addNone(breadNav);
    addNone(Calculate.detailTPSContainer);
  } else {
    Calculate.detailTPS(
      provinsiDropdown.value,
      kabkotaDropdown.value,
      kecamatanDropdown.value,
      desaDropdown.value,
      this.value
    );
    removesNone(breadNav);
    appendCrumb("tps", tpsOption.getValue().label, tpsOption.getValue(true));
  }
});

desaDropdown.addEventListener("change", function () {
  let catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  if (this.value == "") {
    router.navigate(
      `/${catPem}/${jenisSuaraDropdown.value}/${kecamatanDropdown.value}`
    );
    addNone(breadNav);
    addNone(tpsContainer);
    addNone(Calculate.chartData);
    addNone(Calculate.tableData);
    addNone(Calculate.detailTPSContainer);
  } else {
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${this.value}`);
    removesNone(tpsContainer);
    removesNone(breadNav);
    loadTPS(
      provinsiDropdown.value,
      kabkotaDropdown.value,
      kecamatanDropdown.value,
      this.value
    );
    appendCrumb(
      "kelurahan",
      desaOption.getValue().label,
      desaOption.getValue(true)
    );
  }
});

// func tps
export const loadTPS = (paramsProv, paramsKab, paramsKec, params) => {
  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  let catPem = null;
  if (router.lastRouteResolved().params.wilayah.length <= 10) {
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  }
  fetch(
    `${baseUrl}${refURL}/wilayah/${paramsProv}/${paramsKab}/${paramsKec}/${params}.json`
  )
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      let arrChoices = [];
      for (let index in data) {
        arrChoices.push({
          value: index,
          label: data[index].nama,
        });
      }
      tpsOption.clearChoices();
      tpsOption.setChoices(
        [{ value: "", label: "Semua TPS", placeholder: true }],
        "value",
        "label",
        false
      );
      tpsOption.setChoiceByValue("");
      tpsOption.setChoices(arrChoices, "value", "label", false);
      if (
        router.lastRouteResolved().params.wilayah !== "" &&
        router.lastRouteResolved().params.wilayah !== null &&
        router.lastRouteResolved().params.wilayah !== undefined
      ) {
        let lengthParams = null;
        if (router.lastRouteResolved().params.wilayah.length === 13) {
          lengthParams = router.lastRouteResolved().params.wilayah;
        } else {
          lengthParams = router
            .lastRouteResolved()
            .params.wilayah.substring(0, 13);
        }
        tpsOption.setChoiceByValue(lengthParams);
        if (router.lastRouteResolved().params.wilayah.length >= 13) {
          appendCrumb(
            "tps",
            tpsOption.getValue().label,
            tpsOption.getValue(true)
          );
          removesNone(detailFilter);
          Calculate.detailTPS(
            paramsProv,
            paramsKab,
            paramsKec,
            params,
            tpsOption.getValue(true)
          );
        } else {
          Calculate.calcDatas(
            data,
            "kelurahan",
            params,
            jenisSuaraDropdown.value
          );
        }
      }
    })
    .catch((error) => console.log(error));
};

// func kelurahan
kecamatanDropdown.addEventListener("change", function () {
  let catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  if (this.value == "") {
    router.navigate(
      `/${catPem}/${jenisSuaraDropdown.value}/${kabkotaDropdown.value}`
    );
    addNone(breadNav);
    addNone(desaContainer);
    addNone(tpsContainer);
    addNone(Calculate.chartData);
    addNone(Calculate.tableData);
    addNone(Calculate.detailTPSContainer);
  } else {
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${this.value}`);
    loadKel(provinsiDropdown.value, kabkotaDropdown.value, this.value);
    removesNone(breadNav);
    addNone(tpsContainer);
    appendCrumb(
      "kecamatan",
      kecamatanOption.getValue().label,
      kecamatanOption.getValue(true)
    );
  }
});
export const loadKel = (paramsProv, paramsKab, params) => {
  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  let catPem = null;
  if (router.lastRouteResolved().params.wilayah.length <= 6) {
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  }
  fetch(`${baseUrl}${refURL}/wilayah/${paramsProv}/${paramsKab}/${params}.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      let arrChoices = [];
      for (let index in data) {
        arrChoices.push({
          value: index,
          label: data[index].nama,
        });
      }
      desaOption.clearChoices();
      desaOption.setChoices(
        [{ value: "", label: "Semua Desa/Kelurahan", placeholder: true }],
        "value",
        "label",
        false
      );
      desaOption.setChoiceByValue("");
      tpsOption.clearChoices();
      tpsOption.setChoices(
        [{ value: "", label: "Semua TPS", placeholder: true }],
        "value",
        "label",
        false
      );
      tpsOption.setChoiceByValue("");
      desaOption.setChoices(arrChoices, "value", "label", false);
      if (
        router.lastRouteResolved().params.wilayah !== "" &&
        router.lastRouteResolved().params.wilayah !== null &&
        router.lastRouteResolved().params.wilayah !== undefined
      ) {
        let lengthParams = null;
        if (router.lastRouteResolved().params.wilayah.length === 10) {
          lengthParams = router.lastRouteResolved().params.wilayah;
        } else {
          lengthParams = router
            .lastRouteResolved()
            .params.wilayah.substring(0, 10);
        }
        desaOption.setChoiceByValue(lengthParams);
        if (router.lastRouteResolved().params.wilayah.length >= 10) {
          appendCrumb(
            "kelurahan",
            desaOption.getValue().label,
            desaOption.getValue(true)
          );
          loadTPS(paramsProv, paramsKab, params, desaOption.getValue(true));
          removesNone(detailFilter);
        }
      }
      Calculate.calcDatas(data, "kecamatan", params, jenisSuaraDropdown.value);
    })
    .catch((error) => console.log(error));
};

// func load kec
export const loadKec = (provParams, params) => {
  if (
    document.querySelector("#main-container").classList.contains("collapsed")
  ) {
    document
      .querySelector("#main-container.collapsed")
      .classList.add("col-bsize");
  } else {
    if (screenDimension[0] >= 1024)
      document.querySelector("#main-container").classList.add("col-bsize");
  }

  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  let catPem = null;
  if (
    !router.lastRouteResolved().params ||
    router.lastRouteResolved().params.wilayah.length <= 4
  ) {
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  }
  fetch(`${baseUrl}${refURL}/wilayah/${provParams}/${params}.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      let arrChoices = [];
      for (let index in data) {
        arrChoices.push({
          value: index,
          label: data[index].nama,
        });
      }
      kecamatanOption.clearChoices();
      kecamatanOption.setChoices(
        [{ value: "", label: "Semua Kecamatan", placeholder: true }],
        "value",
        "label",
        false
      );
      kecamatanOption.setChoiceByValue("");
      desaOption.clearChoices();
      desaOption.setChoices(
        [{ value: "", label: "Semua Desa/Kelurahan", placeholder: true }],
        "value",
        "label",
        false
      );
      desaOption.setChoiceByValue("");
      tpsOption.clearChoices();
      tpsOption.setChoices(
        [{ value: "", label: "Semua TPS", placeholder: true }],
        "value",
        "label",
        false
      );
      tpsOption.setChoiceByValue("");
      kecamatanOption.setChoices(arrChoices, "value", "label", false);
      if (
        router.lastRouteResolved().params.wilayah !== "" &&
        router.lastRouteResolved().params.wilayah !== null &&
        router.lastRouteResolved().params.wilayah !== undefined
      ) {
        let lengthParams = null;
        if (router.lastRouteResolved().params.wilayah.length === 6) {
          lengthParams = router.lastRouteResolved().params.wilayah;
        } else {
          lengthParams = router
            .lastRouteResolved()
            .params.wilayah.substring(0, 6);
        }
        kecamatanOption.setChoiceByValue(lengthParams);
        if (router.lastRouteResolved().params.wilayah.length >= 6) {
          appendCrumb(
            "kecamatan",
            kecamatanOption.getValue().label,
            kecamatanOption.getValue(true)
          );
          loadKel(provParams, params, kecamatanOption.getValue(true));
          removesNone(detailFilter);
        }
      }
      Calculate.calcDatas(data, "kabkota", params, jenisSuaraDropdown.value);
    })
    .catch((error) => console.log(error));
};

kabkotaDropdown.addEventListener("change", function () {
  let catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  if (this.value == "") {
    router.navigate(
      `/${catPem}/${jenisSuaraDropdown.value}/${provinsiDropdown.value}`
    );
    addNone(detailFilter);
    addNone(breadNav);
    addNone(Calculate.chartData);
    addNone(Calculate.tableData);
    addNone(Calculate.detailTPSContainer);
    removesNone(document.getElementById("chartHome"));
    removeAllComponent(document.getElementById("pieChartContainer"));
    initLanding();
  } else {
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${this.value}`);
    removesNone(detailFilter);
    removesNone(kecamatanContainer);
    removesNone(breadNav);
    addNone(tpsContainer);
    addNone(desaContainer);
    loadKec(provinsiDropdown.value, this.value);
    appendCrumb(
      "kabkota",
      kabkotaOption.getValue().label,
      kabkotaOption.getValue(true)
    );
    removesNone(jenisSuaraContainer);
    addNone(document.getElementById("chartHome"));
    removeAllComponent(document.getElementById("pieChartContainer"));
  }
  if (jenisSuaraDropdown.value.toString() === "penetapan")
    addNone(detailFilter),
      addNone(Calculate.chartData),
      addNone(Calculate.tableData),
      addNone(Calculate.detailTPSContainer);
});

export const loadKabKota = (params) => {
  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  let idProv = params,
    catPem = null;
  if (!router.lastRouteResolved().params) {
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${idProv}`);
  }
  fetch(`${baseUrl}${refURL}/wilayah/${idProv}.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      Calculate.dataPaslon().then((dataPaslon) => {
        let arrChoices = [];
        if (firstDropdown.value == "pkwkp") {
          for (let index in data) {
            arrChoices.push({
              value: index,
              label: data[index].nama,
            });
          }
        } else {
          for (let index in dataPaslon[params]) {
            arrChoices.push({
              value: index,
              label: data[index].nama,
            });
          }
        }
        if (kabkotaOption.getValue(true).toString() === "") {
          kabkotaOption.clearChoices();
          if (firstDropdown.value === "pkwkp") {
            kabkotaOption.setChoices(
              [{ value: "", label: "Semua Kabupaten/Kota", placeholder: true }],
              "value",
              "label",
              false
            );
          } else {
            kabkotaOption.setChoices(
              [{ value: "", label: "Pilih Kabupaten/Kota", placeholder: true }],
              "value",
              "label",
              false
            );
          }
          kabkotaOption.setChoiceByValue("");
          kabkotaOption.setChoices(arrChoices, "value", "label", false);
        }

        if (
          router.lastRouteResolved().params.wilayah !== "" &&
          router.lastRouteResolved().params.wilayah !== null &&
          router.lastRouteResolved().params.wilayah !== undefined
        ) {
          let lengthParams = null;
          if (router.lastRouteResolved().params.wilayah.length === 4) {
            lengthParams = router.lastRouteResolved().params.wilayah;
          } else {
            lengthParams = router
              .lastRouteResolved()
              .params.wilayah.substring(0, 4);
          }
          kabkotaOption.setChoiceByValue(lengthParams);
          if (router.lastRouteResolved().params.wilayah.length >= 4) {
            appendCrumb(
              "kabkota",
              kabkotaOption.getValue().label,
              kabkotaOption.getValue(true)
            );
            addNone(document.getElementById("chartHome"));
            loadKec(params, kabkotaOption.getValue(true));
            removesNone(detailFilter);
            removesNone(kecamatanContainer);
          }
        }
      });
      kecamatanOption.clearChoices();
      kecamatanOption.setChoices(
        [{ value: "", label: "Semua Kecamatan", placeholder: true }],
        "value",
        "label",
        false
      );
      kecamatanOption.setChoiceByValue("");
      desaOption.clearChoices();
      desaOption.setChoices(
        [{ value: "", label: "Semua Desa/Kelurahan", placeholder: true }],
        "value",
        "label",
        false
      );
      desaOption.setChoiceByValue("");
      tpsOption.clearChoices();
      tpsOption.setChoices(
        [{ value: "", label: "Semua TPS", placeholder: true }],
        "value",
        "label",
        false
      );
      tpsOption.setChoiceByValue("");
      if (firstDropdown.value == "pkwkp") {
        Calculate.calcDatas(
          data,
          "provinsiGub",
          undefined,
          jenisSuaraDropdown.value
        );
      } else {
        Calculate.calcDatas(
          data,
          "provinsi",
          undefined,
          jenisSuaraDropdown.value
        );
      }
      if (MapsV.btnTogglerMaps.innerText == "Sembunyikan Peta") {
        MapsV.btnTogglerMaps.dispatchEvent(new Event("click"));
      }
    })
    .catch((error) => console.log(error));
};

jenisSuaraDropdown.addEventListener("change", function () {
  document
    .querySelector("#main-container.collapsed")
    .classList.remove("col-bsize");
  let catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk"),
    router.navigate(`/${catPem}/${this.value}/${provinsiDropdown.value}`);
  addNone(detailFilter);
  provinsiOption.clearStore();
  provinsiOption.setChoices(
    [{ value: "", label: "Pilih Provinsi", placeholder: true }],
    "value",
    "label",
    false
  );
  provinsiOption.setChoiceByValue("");
  loadProv();
  if (this.value === "tungsura" || this.value === "rekapitulasi") {
    loadKabKota(provinsiDropdown.value.toString());
    addNone(document.getElementById("chartHome"));
    addNone(Calculate.detailPenetapan);
    if (kabkotaOption.getValue(true).toString() !== null)
      router.navigate(
        `/${catPem}/${
          jenisSuaraDropdown.value
        }/${kabkotaDropdown.value.toString()}`
      ),
        loadKabKota(router.lastRouteResolved().params.wilayah.substring(0, 2));
  } else {
    return (
      router.navigate(
        `/${catPem}/${
          jenisSuaraDropdown.value
        }/${kabkotaDropdown.value.toString()}`
      ),
      loadKabKota(router.lastRouteResolved().params.wilayah.substring(0, 2)),
      addNone(Calculate.chartData),
      addNone(Calculate.tableData),
      addNone(Calculate.detailPenetapan)
    );
  }
});

// func jenis Suara
export const loadJenisSuara = () => {
  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  jenisSuaraOption.clearChoices();
  jenisSuaraOption.setChoices(
    [
      { value: "tungsura", label: "Hitung Suara", selected: true },
      { value: "rekapitulasi", label: "Rekapitulasi" },
      { value: "penetapan", label: "Penetapan" },
    ],
    "value",
    "label",
    false
  );
  if (jenisSuaraDropdown.value.toString() === "penetapan") {
    addNone(detailFilter),
      addNone(Calculate.chartData),
      addNone(Calculate.tableData),
      addNone(Calculate.detailTPSContainer);
    if (firstDropdown.value.toString() === "pkwkp") addNone(kabkotaContainer);
  }
};

provinsiDropdown.addEventListener("change", function () {
  document
    .querySelector("#main-container.collapsed")
    .classList.remove("col-bsize");
  if (this.value == "") {
    router.navigate(`/${firstDropdown.value}/`);
    addNone(kabkotaContainer);
    addNone(jenisSuaraContainer);
    addNone(detailFilter);
    addNone(breadNav);
    addNone(Calculate.chartData);
    addNone(Calculate.tableData);
    addNone(Calculate.detailTPSContainer);
  } else {
    kabkotaOption.clearChoices();
    kabkotaOption.setChoices(
      [{ value: "", label: "Semua Kabupaten/Kota", placeholder: true }],
      "value",
      "label",
      false
    );
    kabkotaOption.setChoiceByValue("");
    let catPem = null;
    firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
    router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${this.value}`);
    loadKabKota(this.value);
    removesNone(kabkotaContainer);
    removesNone(breadNav);
    addNone(detailFilter);
    addNone(kecamatanContainer);
    checkOrdering();
    appendCrumb(
      "provinsi",
      provinsiOption.getValue().label,
      provinsiOption.getValue(true)
    );
    removesNone(jenisSuaraContainer);
    addNone(document.getElementById("chartHome"));
    removeAllComponent(document.getElementById("pieChartContainer"));
  }
  if (jenisSuaraDropdown.value.toString() === "penetapan") {
    addNone(detailFilter),
      addNone(Calculate.chartData),
      addNone(Calculate.tableData),
      addNone(Calculate.detailTPSContainer);
    if (firstDropdown.value.toString() === "pkwkp") {
      addNone(kabkotaContainer);
    } else {
      kabkotaOption.setChoiceByValue("");
      addNone(Calculate.detailPenetapan);
    }
  }
});

export const showAlert = (message = "Belum tersedia") => {
  let div = document.createElement("div");
  div.setAttribute("class", "alert alert-danger text-center");
  div.setAttribute("role", "alert");
  div.appendChild(document.createTextNode(message.toString()));
  removeAllComponent(noticeData);
  noticeData.appendChild(div);
  removesNone(noticeData);
  addNone(Calculate.detailPenetapan);
};

export const removesNone = (component) => {
  component.classList.remove("d-none");
  component.classList.add("zerowidth");
  setTimeout(function () {
    component.classList.remove("zerowidth");
  }, 250);
};

export const addNone = (component) => {
  component.classList.add("d-none");
  component.classList.remove("zerowidth");
  setTimeout(function () {
    component.classList.add("zerowidth");
  }, 250);
};

// func provinsi
export const loadProv = () => {
  addNone(noticeData);
  Calculate.detailTPSContainer.classList.add("d-none");
  fetch(`${baseUrl}${refURL}/wilayah/0.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      Calculate.dataPaslon().then((dataPaslon) => {
        let arrChoices = [];
        for (let index in dataPaslon) {
          arrChoices.push({
            value: index,
            label: data[index].nama,
          });
        }
        provinsiOption.setChoices(arrChoices, "value", "label", false);
        if (
          router.lastRouteResolved().params !== "" &&
          router.lastRouteResolved().params !== null &&
          router.lastRouteResolved().params !== undefined
        ) {
          let lengthParams = null;
          if (router.lastRouteResolved().params.wilayah.length === 2) {
            lengthParams = router.lastRouteResolved().params.wilayah;
          } else {
            lengthParams = router
              .lastRouteResolved()
              .params.wilayah.substring(0, 2);
          }
          provinsiOption.setChoiceByValue(lengthParams);
          if (router.lastRouteResolved().params.wilayah.length >= 2) {
            loadJenisSuara();
            appendCrumb(
              "provinsi",
              provinsiOption.getValue().label,
              provinsiOption.getValue(true)
            );
            addNone(document.getElementById("chartHome"));
            if (jenisSuaraDropdown.value.toString() === "tungsura") {
              removesNone(kabkotaContainer),
                loadKabKota(provinsiOption.getValue(true));
            } else if (jenisSuaraDropdown.value.toString() === "rekapitulasi") {
              removesNone(kabkotaContainer),
                loadKabKota(provinsiOption.getValue(true));
            } else if (jenisSuaraDropdown.value.toString() === "penetapan") {
              if (firstDropdown.value.toString() === "pkwkp") {
                addNone(kabkotaContainer),
                  loadKabKota(provinsiOption.getValue(true));
              } else {
                removesNone(kabkotaContainer),
                  loadKabKota(provinsiOption.getValue(true));
              }
            }
            checkOrdering();
          }
        }
        loadJenisSuara();
        if (router.lastRouteResolved().params)
          jenisSuaraOption.setChoiceByValue(
            router.lastRouteResolved().params.jenisdata.toString()
          );
      });
    })
    .catch((error) => console.log(error));
};

breadProv.addEventListener("click", function () {
  document.getElementById("main-container").classList.remove("col-bsize");
  let params = null,
    catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  router.lastRouteResolved().params.wilayah >= 2
    ? (params = router.lastRouteResolved().params.wilayah.substring(0, 2))
    : (params = router.lastRouteResolved().params.wilayah);
  router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  addNone(detailFilter);
  loadProv();
});

breadKab.addEventListener("click", function () {
  let params = null,
    catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  router.lastRouteResolved().params.wilayah >= 4
    ? (params = router.lastRouteResolved().params.wilayah.substring(0, 4))
    : (params = router.lastRouteResolved().params.wilayah);
  router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  addNone(desaContainer);
  loadKabKota(params.substring(0, 2));
});

breadKec.addEventListener("click", function () {
  let params = null,
    catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  router.lastRouteResolved().params.wilayah >= 6
    ? (params = router.lastRouteResolved().params.wilayah.substring(0, 6))
    : (params = router.lastRouteResolved().params.wilayah);
  router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  loadKec(params.substring(0, 2), params.substring(0, 4));
});

breadKel.addEventListener("click", function () {
  let params = null,
    catPem = null;
  firstDropdown.value === "pkwkp" ? (catPem = "pkwkp") : (catPem = "pkwkk");
  router.lastRouteResolved().params.wilayah >= 10
    ? (params = router.lastRouteResolved().params.wilayah.substring(0, 10))
    : (params = router.lastRouteResolved().params.wilayah);
  router.navigate(`/${catPem}/${jenisSuaraDropdown.value}/${params}`);
  loadKel(
    params.substring(0, 2),
    params.substring(0, 4),
    params.substring(0, 6)
  );
});

const loadRouter = () => {
  router
    .on({
      "/": () => {
        router.navigate("/pkwkp");
      },
      pkwkp: () => {},
      "pkwkp/:jenisdata/:wilayah": (params, query) => {},
      pkwkk: () => {},
      "pkwkk/:jenisdata/:wilayah": (params, query) => {},
      "pkwkk/:child": () => {
        return addNone(Calculate.tableData), addNone(Calculate.chartData);
      },
      "pkwkp/:child": () => {
        return addNone(Calculate.tableData), addNone(Calculate.chartData);
      },
    })
    .resolve();
};

export const initLanding = () => {
  fetch(`${baseUrl}${Calculate.hhcURL}/pkwkp/0.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      Calculate.dataPaslon().then((dataPaslon) => {
        let katPas = undefined,
          landingLabel = document.getElementById("landingLabel"),
          landingLabelHeader = document.getElementById("landingLabelHeader"),
          container = document.getElementById("pieChartContainer");
        landingLabelHeader.innerText = "Hitung Suara";
        if (firstDropdown.value === "pkwkp") {
          landingLabel.innerText = "Pemilihan Gubernur dan Wakil Gubernur";
          katPas = dataPaslon;
        } else {
          landingLabel.innerText =
            "Pemilihan Bupati/Walikota dan Wakil Bupati/Walikota";
          katPas = dataPaslon[provinsiDropdown.value];
        }
        removeAllComponent(container);
        for (let indexPaslon in katPas) {
          let makeFirstDiv = document.createElement("div");
          makeFirstDiv.className = "col-12 col-lg-4 mb-2";
          let makeSecondDiv = document.createElement("div");
          makeSecondDiv.className = "card h-100";
          let makeThirdDiv = document.createElement("div");
          makeThirdDiv.className = "card-body";
          let aHref = document.createElement("a");
          aHref.style.cursor = "pointer";
          aHref.className = "chartLanding";
          let hSix = document.createElement("h6");
          hSix.className = "text-center text-uppercase";
          let makeFourDiv = document.createElement("div");
          makeFourDiv.className = "d-flex justify-content-center";
          let makeSpan = document.createElement("span");
          makeSpan.className = "badge bg-secondary";
          let makeChart = document.createElement("div");
          let classDynamic = `landing-chart${indexPaslon}`;
          makeChart.id = classDynamic;

          let makeProgDiv = document.createElement("div");
          makeProgDiv.className = "d-flex justify-content-center";
          let makeSpanProg = document.createElement("span");
          makeSpanProg.className = "badge bg-secondary";

          if (firstDropdown.value === "pkwkp") {
            makeThirdDiv.appendChild(aHref);
            if (data.table_ts !== undefined)
              makeSpan.innerText = `Versi: ${
                data.table_ts[indexPaslon] === undefined
                  ? "Belum Tersedia"
                  : data.table_ts[indexPaslon]
              }`;
            Calculate.dataWilayah().then((dataWilayah) => {
              let arrChart = [];
              hSix.setAttribute("data-id", indexPaslon);
              hSix.setAttribute("data-category", firstDropdown.value);
              hSix.appendChild(
                document.createTextNode(dataWilayah[indexPaslon].nama)
              );
              aHref.appendChild(hSix);
              for (let indexValue in data.table[indexPaslon]) {
                arrChart.push({
                  name: katPas[indexPaslon][indexValue].nama,
                  y: data.table[indexPaslon][indexValue],
                });
              }
              Calculate.calcChart(arrChart, classDynamic);
            });
            if (data.table_progress !== undefined)
              makeSpanProg.innerText =
                data.table_progress[indexPaslon] === undefined
                  ? "Belum Tersedia"
                  : data.table_progress[indexPaslon];
          } else {
            makeThirdDiv.appendChild(aHref);
            Calculate.dataWilayahKabKota("wilayah").then(
              (dataWilayahKabKota) => {
                hSix.setAttribute("data-id", indexPaslon);
                hSix.setAttribute("data-category", firstDropdown.value);
                hSix.appendChild(
                  document.createTextNode(dataWilayahKabKota[indexPaslon].nama)
                );
                aHref.appendChild(hSix);
              }
            );
            Calculate.dataWilayahKabKota("data").then((dataKabKota) => {
              makeSpan.innerText = `Versi: ${dataKabKota.ts}`;
              let arrChart = [];
              for (let indexValue in dataKabKota.table[indexPaslon]) {
                arrChart.push({
                  name: katPas[indexPaslon][indexValue].nama,
                  y: dataKabKota.table[indexPaslon][indexValue],
                });
              }
              Calculate.calcChart(arrChart, classDynamic);
              makeSpanProg.innerText = dataKabKota.table_progres[indexPaslon];
            });
          }

          makeFirstDiv.appendChild(makeSecondDiv);
          makeSecondDiv.appendChild(makeThirdDiv);
          makeThirdDiv.appendChild(makeFourDiv);
          makeFourDiv.appendChild(makeSpan);
          makeThirdDiv.appendChild(makeChart);
          makeProgDiv.appendChild(makeSpanProg);
          makeThirdDiv.appendChild(makeProgDiv);

          container.appendChild(makeFirstDiv);
        }
        triggerChartLanding();
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

const triggerChartLanding = () => {
  let chartLanding = document.querySelectorAll(".chartLanding");
  chartLanding.forEach((el) =>
    el.addEventListener("click", (event) => {
      let id = event.target.dataset.id,
        category = event.target.dataset.category;
      if (category.toString() === "pkwkp") {
        let locaData = id.toString();
        loadProv();
        provinsiOption.setChoiceByValue(locaData);
        removesNone(kabkotaContainer);
        loadKabKota(locaData);
        appendCrumb(
          "provinsi",
          provinsiOption.getValue().label,
          provinsiOption.getValue(true)
        );
        let catPem = null;
        firstDropdown.value === "pkwkp"
          ? (catPem = "pkwkp")
          : (catPem = "pkwkk");
        router.navigate(`/${catPem}/tungsura/${id}`);
      } else {
        let sliceN = id.toString().slice(0, 2);
        loadProv();
        loadKabKota(sliceN);
        loadKec(sliceN, id);
        provinsiOption.setChoiceByValue(sliceN);
        removesNone(kabkotaContainer);
        removesNone(detailFilter);
        removesNone(kecamatanContainer);
        kabkotaOption.setChoiceByValue(id.toString());
        appendCrumb(
          "provinsi",
          provinsiOption.getValue().label,
          provinsiOption.getValue(true)
        );
        appendCrumb(
          "kabkota",
          kabkotaOption.getValue().label,
          kabkotaOption.getValue(true)
        );
      }
    })
  );
};
