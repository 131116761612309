export const configSite = () => {
  return {
    baseUrl: process.env.baseUrl,
    baseURLImage: process.env.baseURLImage,
    title: process.env.title,
    refURL: process.env.refURL,
    hhcURL: process.env.hhcURL,
    hrURL: process.env.hrURL,
    phURL: process.env.phURL,
    baseURLPDF: process.env.baseURLPDF,
    baseURLPDFPenetapan: process.env.baseURLPDFPenetapan,
  };
};
