"use strict";

import * as Config from "./config.js";
import * as Apps from "./app.js";
const Highcharts = require("highcharts");
const PDFObject = require("pdfobject");
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
import "../../vendors/simplelightbox-master/code/simple-lightbox.js";

const baseUrl = Config.configSite().baseUrl;
const baseURLImage = Config.configSite().baseURLImage;
const baseURLPDF = Config.configSite().baseURLPDF;
const baseURLPDFPenetapan = Config.configSite().baseURLPDFPenetapan;
const refURL = Config.configSite().refURL;
export const hhcURL = Config.configSite().hhcURL;
const hrURL = Config.configSite().hrURL;
const phURL = Config.configSite().phURL;

// Initialize component id's
export const detailTPSContainer = document.getElementById("detailTPS");
const btnImage = document.getElementById("pindaiDokumenBtn");
const btnImageUlang = document.getElementById("pindaiDokumenBtnUlang");
export const chartData = document.getElementById("chartData");
export const tableData = document.getElementById("tableData");
const tableHead = document.getElementById("tableHead");
const tableBody = document.getElementById("tableBody");
const textNotif = document.getElementById("textNotif");
const textNotifUlang = document.getElementById("textNotifUlang");
export const rekapLabel = document.getElementById("rekapLabel");
export const detailPenetapan = document.getElementById("detailPenetapan");

export const dataPaslon = () => {
  let idPaslon = document.getElementById("walkotgov");
  return fetch(`${baseUrl}${refURL}/${idPaslon.value}.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const dataWilayah = () => {
  return fetch(`${baseUrl}${refURL}/wilayah/0.json`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const dataWilayahKabKota = (params) => {
  let idPaslon = document.getElementById("walkotgov");
  let toURL =
    params === "data"
      ? `${baseUrl}${hhcURL}/${idPaslon.value}/${Apps.provinsiDropdown.value}.json`
      : `${baseUrl}${refURL}/wilayah/${Apps.provinsiDropdown.value}.json`;
  return fetch(toURL)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const calcDatas = (params, levels, values, categories) => {
  Apps.addNone(rekapLabel);
  let idProv = document.getElementById("provinsi"),
    idKab = document.getElementById("kabkota"),
    idKec = document.getElementById("kecamatan"),
    idKel = document.getElementById("desa"),
    kategoriPem = document.getElementById("walkotgov"),
    spanTop = document.getElementById("tsTop"),
    spanRight = document.getElementById("tsRight"),
    txtUSLHeader = document.getElementById("txtHeaderUSL"),
    spanBot = document.getElementById("tsBot"),
    txtHeaderTable = document.getElementById("txtHeaderTable"),
    txtHeaderTableTitle = document.getElementById("txtHeaderTableTitle"),
    txtHeaderLocus = document.getElementById("txtHeaderLocus"),
    titleDesc = document.getElementById("titleDesc"),
    titleDescHeader = document.getElementById("titleDescHeader"),
    locusDesc = document.getElementById("locusDesc"),
    urlHierarki = "",
    dataHierarki = "",
    catHierarki = "";
  if (categories === "tungsura") {
    catHierarki = hhcURL;
  } else if (categories === "rekapitulasi") {
    catHierarki = hrURL;
  } else if (categories === "penetapan") {
    if (Apps.firstDropdown.value === "pkwkp") {
      return (
        Apps.addNone(chartData),
        Apps.addNone(tableData),
        Apps.addNone(detailTPSContainer),
        Apps.addNone(Apps.kabkotaContainer),
        Apps.removesNone(detailPenetapan),
        penetapanRekapitulasi()
      );
    } else {
      if (
        Apps.kabkotaDropdown.value !== "" &&
        Apps.kabkotaDropdown.value !== null
      ) {
        return (
          Apps.addNone(chartData),
          Apps.addNone(tableData),
          Apps.addNone(detailTPSContainer),
          Apps.addNone(Apps.kecamatanContainer),
          Apps.removesNone(detailPenetapan),
          penetapanRekapitulasi()
        );
      } else {
        return (
          Apps.addNone(detailPenetapan),
          Apps.addNone(chartData),
          Apps.addNone(tableData),
          Apps.addNone(detailTPSContainer),
          Apps.addNone(Apps.kecamatanContainer)
        );
      }
    }
  }

  if (levels == "kabkota") {
    urlHierarki = `${baseUrl}${catHierarki}/${kategoriPem.value}/${idProv.value}/${values}.json`;
    dataHierarki = "kec";
  } else if (levels == "kecamatan") {
    urlHierarki = `${baseUrl}${catHierarki}/${kategoriPem.value}/${idProv.value}/${idKab.value}/${values}.json`;
    dataHierarki = "kel";
  } else if (levels == "kelurahan") {
    urlHierarki = `${baseUrl}${catHierarki}/${kategoriPem.value}/${idProv.value}/${idKab.value}/${idKec.value}/${values}.json`;
    dataHierarki = "tps";
  } else if (levels == "provinsiGub") {
    urlHierarki = `${baseUrl}${catHierarki}/${kategoriPem.value}/${idProv.value}.json`;
    dataHierarki = "kabGub";
  } else if (levels == "provinsi") {
    if (categories === "tungsura") {
      Apps.removesNone(document.getElementById("chartHome")),
        Apps.removeAllComponent(document.getElementById("pieChartContainer")),
        Apps.initLanding();
    } else if (categories === "rekapitulasi") {
      Apps.addNone(document.getElementById("chartHome"));
    }
    return Apps.addNone(chartData), Apps.addNone(tableData);
  }

  fetch(`${urlHierarki}`)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      dataPaslon().then((dataPaslon) => {
        let arrChart = [];
        let katPas = undefined;
        kategoriPem.value == "pkwkp"
          ? (katPas = dataPaslon[idProv.value])
          : (katPas = dataPaslon[idProv.value][idKab.value]);
        for (let index in katPas) {
          arrChart.push({
            name: katPas[index].nama,
            y: data.chart[index],
          });
        }
        calcChart(arrChart, "vote-chart");
      });
      if (tableData.classList.contains("col-lg-12"))
        tableData.classList.add("col-lg-8"),
          tableData.classList.remove("col-lg-12");
      Apps.removesNone(chartData);

      if (Apps.jenisSuaraDropdown.value === "tungsura") {
        Apps.addNone(rekapLabel);
        calcTable(
          data.table,
          data.table_progres,
          params,
          dataHierarki,
          data.table_flag_image,
          null,
          data.table_usl
        );
      } else if (Apps.jenisSuaraDropdown.value === "rekapitulasi") {
        calcTable(
          data.table,
          data.pantau,
          params,
          dataHierarki,
          null,
          data.dokumen,
          data.table_usl
        );
      }

      Apps.removesNone(tableData);
      let condLabel,
        condRight = undefined,
        condUSL;
      if (kategoriPem.value === "pkwkp") {
        txtHeaderLocus.innerText = `${Apps.provinsiDropdown.innerText}`;
      } else {
        txtHeaderLocus.innerText = `${Apps.kabkotaDropdown.innerText}`;
      }
      if (Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi") {
        condLabel = `Versi: ${data.ts}`;
        txtHeaderTableTitle.innerText =
          data.usl == "" || data.usl == "Reguler"
            ? "REKAPITULASI"
            : `REKAPITULASI (${data.usl})`;
      } else if (Apps.jenisSuaraDropdown.value.toString() === "penetapan") {
        (condLabel = `Versi: ${data.ts}`),
          (spanRight.className = "badge bg-info");
      } else if (Apps.jenisSuaraDropdown.value.toString() === "tungsura") {
        txtHeaderTableTitle.innerText = "HITUNG SUARA";
        if (dataHierarki === "kec") {
          condUSL =
            data.usl == ""
              ? `KABUPATEN ${idKab.innerText}`
              : `KABUPATEN ${idKab.innerText} (${data.usl})`;
        } else if (dataHierarki === "kel") {
          condUSL =
            data.usl == ""
              ? `KECAMATAN ${idKec.innerText}`
              : `KECAMATAN ${idKec.innerText} (${data.usl})`;
        } else if (dataHierarki === "tps") {
          condUSL =
            data.usl == ""
              ? `KELURAHAN ${idKel.innerText}`
              : `KELURAHAN ${idKel.innerText} (${data.usl})`;
        } else if (dataHierarki === "kabGub") {
          condUSL =
            data.usl == ""
              ? `PROVINSI ${idProv.innerText}`
              : `PROVINSI ${idProv.innerText} (${data.usl})`;
        }

        (condLabel = `Versi: ${data.ts}`),
          (condRight = `Progress: ${data.progres}`),
          (spanRight.className = "badge bg-info");
      }
      spanTop.textContent = condLabel;
      spanBot.textContent = condLabel;
      spanRight.textContent = condRight;
      txtUSLHeader.textContent = condUSL;

      let objText = {
        gubernur: "PEMILIHAN GUBERNUR DAN WAKIL GUBERNUR",
        bupati: "PEMILIHAN BUPATI DAN WAKIL BUPATI",
        walikota: "PEMILIHAN WALIKOTA DAN WAKIL WALIKOTA",
        hs: "HITUNG SUARA",
        rkptls: "REKAPITULASI",
      };

      if (kategoriPem.value === "pkwkp") {
        locusDesc.innerText = Apps.provinsiDropdown.innerText;
        txtHeaderTable.innerText = titleDesc.innerText = objText.gubernur;
        titleDescHeader.innerText =
          Apps.jenisSuaraDropdown.value.toString() === "tungsura"
            ? objText.hs
            : objText.rkptls;
      } else {
        locusDesc.innerText = Apps.kabkotaDropdown.innerText;
        titleDescHeader.innerText =
          Apps.jenisSuaraDropdown.value.toString() === "tungsura"
            ? objText.hs
            : objText.rkptls;
        if (idKab.innerText.includes("KOTA ")) {
          txtHeaderTable.innerText = titleDesc.innerText = objText.walikota;
        } else {
          txtHeaderTable.innerText = titleDesc.innerText = objText.bupati;
        }
      }
    })
    .catch((error) => console.log(error));
};

const penetapanRekapitulasi = () => {
  let txtTitleDetailPenetapan = document.getElementById(
      "txtTitleDetailPenetapan"
    ),
    txtHeaderDetailPenetapan = document.getElementById(
      "txtHeaderDetailPenetapan"
    ),
    detailPenetapanLabel = document.getElementById("detailPenetapanLabel"),
    tablePenetapan = document.getElementById("tablePenetapan"),
    penetapanPaslonTerpilih = document.getElementById(
      "penetapanPaslonTerpilih"
    ),
    pindaiSKPenetapanRekapitulasi = document.getElementById(
      "pindaiSKPenetapanRekapitulasi"
    ),
    textNotifSKPenetapanRekapitulasi = document.getElementById(
      "textNotifSKPenetapanRekapitulasi"
    ),
    pindaiSKPenetapanPaslonTerpilih = document.getElementById(
      "pindaiSKPenetapanPaslonTerpilih"
    ),
    textNotifSKPenetapanPaslonTerpilih = document.getElementById(
      "textNotifSKPenetapanPaslonTerpilih"
    ),
    txtHeaderWilayahPenetapan = document.getElementById(
      "txtHeaderWilayahPenetapan"
    ),
    urlPenetapan = undefined;

  txtTitleDetailPenetapan.innerText = "PENETAPAN REKAPITULASI";
  if (Apps.firstDropdown.value.toString() == "pkwkp") {
    urlPenetapan = `${baseUrl}${phURL}/${Apps.firstDropdown.value}/${Apps.provinsiDropdown.value}.json`;
    txtHeaderWilayahPenetapan.innerText = Apps.provinsiDropdown.innerText;
    txtHeaderDetailPenetapan.innerText =
      "PEMILIHAN GUBERNUR DAN WAKIL GUBERNUR";
    detailPenetapanLabel.innerText =
      "HASIL PENETAPAN REKAPITULASI PEMILIHAN GUBERNUR DAN WAKIL GUBERNUR 2020";
  } else {
    txtHeaderWilayahPenetapan.innerText = Apps.kabkotaDropdown.innerText;
    urlPenetapan = `${baseUrl}${phURL}/${Apps.firstDropdown.value}/${Apps.provinsiDropdown.value}/${Apps.kabkotaDropdown.value}.json`;
    if (Apps.kabkotaDropdown.innerText.includes("KOTA ")) {
      txtHeaderDetailPenetapan.innerText =
        "PEMILIHAN WALIKOTA DAN WAKIL WALIKOTA";
      detailPenetapanLabel.innerText =
        "HASIL PENETAPAN REKAPITULASI PEMILIHAN WALIKOTA DAN WAKIL WALIKOTA 2020";
    } else {
      txtHeaderDetailPenetapan.innerText = "PEMILIHAN BUPATI DAN WAKIL BUPATI";
      detailPenetapanLabel.innerText =
        "HASIL PENETAPAN REKAPITULASI PEMILIHAN BUPATI DAN WAKIL BUPATI 2020";
    }
  }

  fetch(urlPenetapan)
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      Apps.removeAllComponent(tablePenetapan);
      dataPaslon().then((dataPaslon) => {
        let arrChart = [];
        let katPas = undefined;
        Apps.firstDropdown.value.toString() == "pkwkp"
          ? (katPas = dataPaslon[Apps.provinsiDropdown.value])
          : (katPas =
              dataPaslon[Apps.provinsiDropdown.value][
                Apps.kabkotaDropdown.value
              ]);
        if (data.chart === null) return Apps.showAlert();

        for (let index in katPas) {
          arrChart.push({
            name: katPas[index].nama,
            y: data.chart[index],
          });
          let tr = document.createElement("tr");
          let th = document.createElement("th");
          th.className = "text-center";
          th.appendChild(document.createTextNode(index));
          let tdF = document.createElement("td");
          tdF.className = "text-left";
          tdF.appendChild(document.createTextNode(katPas[index].nama));
          let tdS = document.createElement("td");
          tdS.setAttribute("width", "150");
          tdS.appendChild(
            document.createTextNode(
              data.chart[index] !== undefined
                ? curFormat(data.chart[index])
                : "Belum Tersedia"
            )
          );
          tr.appendChild(th);
          tr.appendChild(tdF);
          tr.appendChild(tdS);
          tablePenetapan.appendChild(tr);

          if (
            data.no_urut_terpilih !== null &&
            data.no_urut_terpilih !== undefined
          ) {
            if (parseInt(index) === parseInt(data.no_urut_terpilih))
              penetapanPaslonTerpilih.innerText = katPas[index].nama;
          } else {
            penetapanPaslonTerpilih.innerText = "Belum Tersedia";
          }

          if (
            data.sk_suara.file_dokumen !== null &&
            data.sk_suara.file_dokumen !== undefined
          ) {
            Apps.addNone(textNotifSKPenetapanRekapitulasi);
            pindaiSKPenetapanRekapitulasi.removeAttribute("disabled", true);

            const myModalEl = document.getElementById("detailDokumenRekap");
            detailDokumenRekapLabel.innerText =
              "Salinan SK Penetapan Rekapitulasi";
            myModalEl.addEventListener("show.bs.modal", function (event) {
              PDFObject.embed(
                `${baseURLPDFPenetapan}${Apps.firstDropdown.value}/${
                  data.sk_suara.file_dokumen !== null &&
                  data.sk_suara.file_dokumen !== undefined
                    ? data.sk_suara.file_dokumen
                    : ""
                }`,
                "#pdfInitialize",
                {
                  forcePDFJS: true,
                  fallbackLink:
                    '<p>Browser ini tidak mendukung, Silakan unduh Dokumen untuk melihatnya: <a target="_blank" href="[url]">Unduh Dokumen</a></p>',
                  height: "400px",
                }
              );
            });
          } else {
            pindaiSKPenetapanRekapitulasi.className = "btn btn-secondary";
            Apps.removesNone(textNotifSKPenetapanRekapitulasi);
            pindaiSKPenetapanRekapitulasi.setAttribute("disabled", true);
          }

          if (
            data.sk_terpilih.file_dokumen !== null &&
            data.sk_terpilih.file_dokumen !== undefined
          ) {
            Apps.addNone(textNotifSKPenetapanPaslonTerpilih);
            pindaiSKPenetapanPaslonTerpilih.removeAttribute("disabled", true);

            const myModalEl = document.getElementById("detailDokumenRekap");
            detailDokumenRekapLabel.innerText =
              "Salinan SK Penetapan Paslon Terpilih";
            myModalEl.addEventListener("show.bs.modal", function (event) {
              PDFObject.embed(
                `${baseURLPDFPenetapan}${Apps.firstDropdown.value}/${
                  data.sk_terpilih.file_dokumen !== null &&
                  data.sk_terpilih.file_dokumen !== undefined
                    ? data.sk_terpilih.file_dokumen
                    : ""
                }`,
                "#pdfInitialize",
                {
                  forcePDFJS: true,
                  fallbackLink:
                    '<p>Browser ini tidak mendukung, Silakan unduh Dokumen untuk melihatnya: <a target="_blank" href="[url]">Unduh Dokumen</a></p>',
                  height: "400px",
                }
              );
            });
          } else {
            pindaiSKPenetapanPaslonTerpilih.className = "btn btn-secondary";
            Apps.removesNone(textNotifSKPenetapanPaslonTerpilih);
            pindaiSKPenetapanPaslonTerpilih.setAttribute("disabled", true);
          }
        }
        calcChart(arrChart, "penetapan-chart");
      });
    })
    .catch((err) => {
      Apps.showAlert();
      console.log(err);
    });
};

const curFormat = (value) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else if (value == 0) {
    return "0";
  } else {
    return "";
  }
};

const getDokumenKec = () => {
  let idProv = document.getElementById("provinsi"),
    idKab = document.getElementById("kabkota"),
    idKec = document.getElementById("kecamatan"),
    kategoriPem = document.getElementById("walkotgov");
  return fetch(
    `${baseUrl}${hrURL}/${kategoriPem.value}/${idProv.value}/${idKab.value}/${idKec.value}.json`
  )
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      return data.dokumen;
    })
    .catch((err) => {
      console.log(err);
    });
};

const calcTable = (
  dataTable,
  dataProgres,
  dataLocation,
  dataHierarki,
  dataFlag = null,
  dataDokumen = null,
  dataUsl = null
) => {
  let kategoriPem = document.getElementById("walkotgov"),
    idProv = document.getElementById("provinsi"),
    idKab = document.getElementById("kabkota"),
    idKet = document.getElementById("ketPaslon"),
    tr = document.createElement("tr"),
    th = document.createElement("th"),
    btnDokumen = document.createElement("button"),
    labelDokumen = document.createElement("h6"),
    smallDokumen = document.createElement("small"),
    detailDokumenRekapLabel = document.getElementById(
      "detailDokumenRekapLabel"
    );
  th.setAttribute("scope", "col");
  th.appendChild(document.createTextNode("Wilayah"));
  tr.appendChild(th);

  if (Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi") {
    btnDokumen.type = "button";
    btnDokumen.id = "pindaiDokumenRekap";
    btnDokumen.className = "btn btn-primary";
    btnDokumen.innerText = "Lihat Salinan";

    labelDokumen.className = "font-weight-bold mt-4";
    labelDokumen.innerText = "Salinan Dokumen";

    smallDokumen.id = "textNotifRekap";
    smallDokumen.className = "ml-1";
    smallDokumen.innerText = "Belum Tersedia";
    smallDokumen.style.fontSize = "13px";

    btnDokumen.setAttribute("data-toggle", "modal");
    btnDokumen.setAttribute("data-target", "#detailDokumenRekap");

    detailDokumenRekapLabel.innerText = "Salinan Dokumen Rekapitulasi";

    if (dataDokumen !== null && dataDokumen !== undefined) {
      Apps.addNone(smallDokumen);
      btnDokumen.removeAttribute("disabled", true);

      const myModalEl = document.getElementById("detailDokumenRekap");
      myModalEl.addEventListener("show.bs.modal", function (event) {
        PDFObject.embed(
          `${baseURLPDF}${Apps.firstDropdown.value}/${
            dataDokumen !== null && dataDokumen !== undefined
              ? dataDokumen.file_dokumen
              : ""
          }`,
          "#pdfInitialize",
          {
            forcePDFJS: true,
            fallbackLink:
              '<p>Browser ini tidak mendukung, Silakan unduh Dokumen untuk melihatnya: <a target="_blank" href="[url]">Unduh Dokumen</a></p>',
            height: "400px",
          }
        );
      });
    } else if (dataHierarki === "tps") {
      getDokumenKec().then((dataDokumen) => {
        if (dataDokumen !== null && dataDokumen !== undefined) {
          labelDokumen.innerText = "Salinan Dokumen D.Hasil Kec";
          Apps.addNone(smallDokumen);
          btnDokumen.removeAttribute("disabled", true);

          const myModalEl = document.getElementById("detailDokumenRekap");
          myModalEl.addEventListener("show.bs.modal", function (event) {
            PDFObject.embed(
              `${baseURLPDF}${Apps.firstDropdown.value}/${
                dataDokumen !== null && dataDokumen !== undefined
                  ? dataDokumen.file_dokumen
                  : ""
              }`,
              "#pdfInitialize",
              {
                forcePDFJS: true,
                fallbackLink:
                  '<p>Browser ini tidak mendukung, Silakan unduh Dokumen untuk melihatnya: <a target="_blank" href="[url]">Unduh Dokumen</a></p>',
                height: "400px",
              }
            );
          });
        } else {
          labelDokumen.innerText = "Salinan Dokumen D.Hasil Kec";
          btnDokumen.className = "btn btn-secondary";
          Apps.removesNone(smallDokumen);
          btnDokumen.setAttribute("disabled", true);
        }
      });
    } else {
      btnDokumen.className = "btn btn-secondary";
      Apps.removesNone(smallDokumen);
      btnDokumen.setAttribute("disabled", true);
    }
  }

  dataPaslon().then((dataPaslon) => {
    Apps.removeAllComponent(idKet);
    if (Apps.jenisSuaraDropdown.value.toString() === "tungsura") {
      let katPas = undefined;
      if (kategoriPem.value == "pkwkp") {
        katPas = dataPaslon[idProv.value];
      } else {
        katPas = dataPaslon[idProv.value][idKab.value];
      }
      for (let data in katPas) {
        let li = document.createElement("li");
        li.appendChild(document.createTextNode(katPas[data].nama));
        idKet.appendChild(li);
        let sliceName = katPas[data].nama.split(")");
        let th = document.createElement("th");
        th.setAttribute("scope", "col");
        th.appendChild(document.createTextNode(`${sliceName[0]})`));
        th.appendChild(document.createElement("br"));
        let span = document.createElement("span");
        span.setAttribute("class", "d-none d-lg-inline");
        span.appendChild(document.createTextNode(sliceName[1]));
        th.appendChild(span);
        tr.appendChild(th);
      }
    } else if (Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi") {
      let countObj = [];
      for (let data in dataTable) {
        countObj.push(dataTable[data]);
      }
      if (countObj[0][1] !== null || dataHierarki === "tps") {
        Apps.removesNone(document.getElementById("blockKetPaslon"));
        let katPas = undefined;
        if (kategoriPem.value == "pkwkp") {
          katPas = dataPaslon[idProv.value];
        } else {
          katPas = dataPaslon[idProv.value][idKab.value];
        }
        for (let data in katPas) {
          let li = document.createElement("li");
          li.appendChild(document.createTextNode(katPas[data].nama));
          idKet.appendChild(li);
          let sliceName = katPas[data].nama.split(")");
          let th = document.createElement("th");
          th.setAttribute("scope", "col");
          th.appendChild(document.createTextNode(`${sliceName[0]})`));
          th.appendChild(document.createElement("br"));
          let span = document.createElement("span");
          span.setAttribute("class", "d-none d-lg-inline");
          span.appendChild(document.createTextNode(sliceName[1]));
          th.appendChild(span);
          tr.appendChild(th);
        }
      } else {
        Apps.addNone(document.getElementById("blockKetPaslon"));
        let countObj = [];
        for (let data in dataProgres) {
          countObj.push(dataProgres[data]);
        }
        let tha = document.createElement("th");
        let thb = document.createElement("th");
        tha.setAttribute("scope", "col");
        thb.setAttribute("scope", "col");
        tha.appendChild(document.createTextNode("D.Hasil Kec"));
        thb.appendChild(document.createTextNode("D.Hasil Kab/Ko"));
        if (Object.keys(countObj[0]).length === 1) {
          tr.appendChild(tha);
        } else if (Object.keys(countObj[0]).length === 2) {
          tr.appendChild(tha);
          tr.appendChild(thb);
        }
      }
    }
  });
  Apps.removeAllComponent(tableHead);
  tableHead.appendChild(tr);

  Apps.removeAllComponent(tableBody);
  for (let index in dataLocation) {
    let trb = document.createElement("tr");
    let tdb = document.createElement("td");
    let a = document.createElement("a");
    let spanA = document.createElement("span");
    let spanProgres = document.createElement("span");
    spanProgres.setAttribute("class", "float-right");
    let textUsl = document.createElement("span");
    textUsl.setAttribute("class", "ml-1 font-weight-bold");
    a.style.cursor = "pointer";
    a.setAttribute("class", "tableNodes");
    a.setAttribute("data-whatever", index);
    a.setAttribute("data-child", dataHierarki);

    Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi" &&
    dataHierarki === "tps"
      ? spanA.appendChild(document.createTextNode(dataLocation[index].nama))
      : a.appendChild(document.createTextNode(dataLocation[index].nama));

    if (
      Apps.jenisSuaraDropdown.value.toString() === "tungsura"
    ){
      spanProgres.appendChild(document.createTextNode(dataProgres[index])),
        textUsl.appendChild(
          document.createTextNode(
            dataUsl[index] !== null &&
              dataUsl[index] !== undefined &&
              dataUsl[index] !== ""
              ? `(${dataUsl[index]})`
              : ""
          )
        );
    }else if(
      Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi"
    ){
      if(dataUsl[index] !== "" && dataUsl[index] !== "Reguler"){
        textUsl.appendChild(
          document.createTextNode(
            dataUsl[index] !== null &&
              dataUsl[index] !== undefined &&
              dataUsl[index] !== ""
              ? `(${dataUsl[index]})`
              : ""
          )
        );
      }
    }
      
    Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi" &&
    dataHierarki === "tps"
      ? tdb.appendChild(spanA)
      : tdb.appendChild(a);

    tdb.appendChild(spanProgres);
    tdb.appendChild(textUsl);
    trb.appendChild(tdb);

    if (dataLocation[index]) {
      if (Apps.jenisSuaraDropdown.value.toString() === "tungsura") {
        for (let data in dataTable[index]) {
          let tdbs = document.createElement("td");
          let valtable = null;
          if (dataFlag) {
            if (dataTable[index][data] === null) {
              if (
                dataHierarki === "tps" &&
                dataFlag[index] !== undefined &&
                dataFlag[index] !== null
              ) {
                dataFlag[index] === 0
                  ? ((valtable = "Belum Tersedia"), (tdbs.className = ""))
                  : ((valtable = "Salinan Tersedia"),
                    (tdbs.className = "text-success font-weight-bold"));
              }
            } else {
              valtable = curFormat(dataTable[index][data]);
            }
          } else {
            dataTable[index][data] === null
              ? (valtable = "Belum Tersedia")
              : (valtable = curFormat(dataTable[index][data]));
          }
          tdbs.appendChild(document.createTextNode(valtable));
          trb.appendChild(tdbs);
        }
        tableBody.appendChild(trb);
      } else if (Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi") {
        let countObj = [];
        for (let data in dataTable) {
          countObj.push(dataTable[data]);
        }
        if (countObj[0][1] !== null || dataHierarki === "tps") {
          Apps.addNone(rekapLabel);
          for (let data in dataTable[index]) {
            let tdbs = document.createElement("td");
            let valtable = null;
            dataTable[index][data] === null
              ? (valtable = "Belum Tersedia")
              : (valtable = curFormat(dataTable[index][data]));
            tdbs.appendChild(document.createTextNode(valtable));
            trb.appendChild(tdbs);
          }
        } else {
          Apps.removesNone(rekapLabel);
          for (let data in dataProgres[index]) {
            let tdbs = document.createElement("td");
            if (dataHierarki === "kec") {
              if (dataProgres[index][data].selesai === 1) {
                tdbs.className = "text-primary font-weight-bold";
                tdbs.appendChild(document.createTextNode("Tersedia"));
              } else {
                tdbs.appendChild(document.createTextNode("Belum Tersedia"));
              }
            } else {
              if (
                dataProgres[index][data].total ==
                dataProgres[index][data].selesai
              ) {
                tdbs.className = "text-primary font-weight-bold";
                tdbs.appendChild(document.createTextNode("Tersedia"));
              } else if (dataProgres[index][data].selesai >= 1) {
                tdbs.appendChild(
                  document.createTextNode(
                    `${dataProgres[index][data].selesai} dari ${dataProgres[index][data].total}`
                  )
                );
              } else if (
                dataProgres[index][data].total === 1 ||
                dataProgres[index][data].selesai === 0
              ) {
                tdbs.appendChild(document.createTextNode("Belum Tersedia"));
              }
            }

            trb.appendChild(tdbs);
          }
        }
        tableBody.appendChild(trb);

        tableBody.appendChild(labelDokumen);
        tableBody.appendChild(btnDokumen);
        tableBody.appendChild(smallDokumen);
      }
    }
  }
  triggerNodeTables();
};

const triggerNodeTables = () => {
  let compNodes = document.querySelectorAll(".tableNodes"),
    idProv = document.getElementById("provinsi"),
    idKab = document.getElementById("kabkota"),
    idKec = document.getElementById("kecamatan"),
    idKel = document.getElementById("desa");
  compNodes.forEach((el) =>
    el.addEventListener("click", (event) => {
      let params = event.target.dataset.child,
        nodesData = event.target.dataset.whatever;
      if (params == "kec") {
        Apps.loadKel(idProv.value, idKab.value, nodesData);
        Apps.kecamatanOption.setChoiceByValue(nodesData);
        Apps.appendCrumb("kecamatan", event.target.innerText, nodesData);
        Apps.removesNone(Apps.detailFilter);
        Apps.removesNone(Apps.kecamatanContainer);
        Apps.jenisSuaraDropdown.value.toString() === "rekapitulasi"
          ? Apps.addNone(Apps.tpsContainer)
          : Apps.removesNone(Apps.tpsContainer);
      } else if (params == "kel") {
        Apps.loadTPS(idProv.value, idKab.value, idKec.value, nodesData);
        Apps.desaOption.setChoiceByValue(nodesData);
        Apps.appendCrumb("kelurahan", event.target.innerText, nodesData);
        Apps.removesNone(Apps.detailFilter);
        Apps.removesNone(Apps.desaContainer);
      } else if (params == "kabGub") {
        Apps.loadKec(idProv.value, nodesData);
        Apps.kabkotaOption.setChoiceByValue(nodesData);
        Apps.appendCrumb("kabkota", event.target.innerText, nodesData);
        Apps.removesNone(Apps.detailFilter);
        Apps.removesNone(Apps.kecamatanContainer);
      } else if (params == "tps") {
        detailTPS(
          idProv.value,
          idKab.value,
          idKec.value,
          idKel.value,
          nodesData
        );
        Apps.tpsOption.setChoiceByValue(nodesData);
        Apps.appendCrumb("tps", event.target.innerText, nodesData);
        Apps.removesNone(Apps.detailFilter);
        Apps.removesNone(Apps.tpsContainer);
      }
    })
  );
};

const makeTableHakPilih = (
  compTable,
  dataPenggunaHakPilih,
  dataSuaraDigunakan
) => {
  Apps.removeAllComponent(compTable);
  let trHP = document.createElement("tr"),
    trSD = document.createElement("tr"),
    tdHPs = document.createElement("td"),
    tdHPd = document.createElement("td"),
    tdSDs = document.createElement("td"),
    tdSDd = document.createElement("td"),
    txtPenggunaHakPilih = "Belum Tersedia",
    txtSuratSuaraDigunakan = "Belum Tersedia";
  dataPenggunaHakPilih == null
    ? txtPenggunaHakPilih
    : (txtPenggunaHakPilih = dataPenggunaHakPilih);
  tdHPs.appendChild(document.createTextNode("PENGGUNA HAK PILIH"));
  tdHPd.setAttribute("width", 150);
  tdHPd.appendChild(document.createTextNode(txtPenggunaHakPilih));
  trHP.appendChild(tdHPs);
  trHP.appendChild(tdHPd);
  compTable.appendChild(trHP);

  dataSuaraDigunakan == null
    ? txtSuratSuaraDigunakan
    : (txtSuratSuaraDigunakan = dataSuaraDigunakan);
  tdSDs.appendChild(document.createTextNode("SURAT SUARA DIGUNAKAN"));
  tdSDd.setAttribute("width", 150);
  tdSDd.appendChild(document.createTextNode(txtSuratSuaraDigunakan));
  trSD.appendChild(tdSDs);
  trSD.appendChild(tdSDd);
  compTable.appendChild(trSD);
};

const makeTableJumlahSuara = (
  compTable,
  dataSuaraSah,
  dataSuaraTidakSah,
  dataSuaraTotal
) => {
  Apps.removeAllComponent(compTable);
  let trSah = document.createElement("tr"),
    trTidakSah = document.createElement("tr"),
    trJumlah = document.createElement("tr"),
    thSah = document.createElement("th"),
    thTidakSah = document.createElement("th"),
    thJumlah = document.createElement("th"),
    tdSahSa = document.createElement("td"),
    tdTidakSahSa = document.createElement("td"),
    tdJumlahSa = document.createElement("td"),
    tdSahDa = document.createElement("td"),
    tdTidakSahDa = document.createElement("td"),
    tdJumlahDa = document.createElement("td"),
    txtSah = "Belum Tersedia",
    txtTidakSah = "Belum Tersedia",
    txtJUmlah = "Belum Tersedia";

  dataSuaraSah == null ? txtSah : (txtSah = dataSuaraSah);
  thSah.setAttribute("class", "text-center");
  thSah.appendChild(document.createTextNode("A"));
  tdSahDa.setAttribute("class", "text-left");
  tdSahDa.appendChild(document.createTextNode("JUMLAH SELURUH SUARA SAH"));
  tdSahSa.setAttribute("width", 150);
  tdSahSa.appendChild(document.createTextNode(txtSah));
  trSah.appendChild(thSah);
  trSah.appendChild(tdSahDa);
  trSah.appendChild(tdSahSa);
  compTable.appendChild(trSah);

  dataSuaraTidakSah == null ? txtTidakSah : (txtTidakSah = dataSuaraTidakSah);
  thTidakSah.setAttribute("class", "text-center");
  thTidakSah.appendChild(document.createTextNode("B"));
  tdTidakSahDa.setAttribute("class", "text-left");
  tdTidakSahDa.appendChild(document.createTextNode("JUMLAH SUARA TIDAK SAH"));
  tdTidakSahSa.setAttribute("width", 150);
  tdTidakSahSa.appendChild(document.createTextNode(txtTidakSah));
  trTidakSah.appendChild(thTidakSah);
  trTidakSah.appendChild(tdTidakSahDa);
  trTidakSah.appendChild(tdTidakSahSa);
  compTable.appendChild(trTidakSah);

  dataSuaraTotal == null ? txtJUmlah : (txtJUmlah = dataSuaraTotal);
  thJumlah.setAttribute("class", "text-center");
  thJumlah.appendChild(document.createTextNode("C"));
  tdJumlahDa.setAttribute("class", "text-left");
  tdJumlahDa.appendChild(
    document.createTextNode("JUMLAH SELURUH SUARA SAH DAN SUARA TIDAK SAH")
  );
  tdJumlahSa.setAttribute("width", 150);
  tdJumlahSa.appendChild(document.createTextNode(txtJUmlah));
  trJumlah.appendChild(thJumlah);
  trJumlah.appendChild(tdJumlahDa);
  trJumlah.appendChild(tdJumlahSa);
  compTable.appendChild(trJumlah);
};

export const detailTPS = (
  paramsProv,
  paramsKab,
  paramsKec,
  paramsKel,
  paramsTps
) => {
  const tabs = document.querySelectorAll("#navTabs>li>a"),
    panes = document.querySelectorAll("#navPanes>div");

  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener("click", function () {
      for (let i1 = 0; i1 < tabs.length; i1++)
        tabs[i1].classList.remove("active");

      for (let i2 = 0; i2 < panes.length; i2++)
        panes[i2].classList.remove("show", "active");

      const tab = this.classList,
        pane = document.getElementById(this.getAttribute("aria-controls"))
          .classList;

      tab.add("active");
      pane.add("show", "active");
    });
  }

  Apps.removesNone(detailTPSContainer);
  let katPem = document.getElementById("walkotgov"),
    tableHakPilih = document.getElementById("tableHakPilih"),
    tableHakPilihUlang = document.getElementById("tableHakPilihUlang"),
    tablePerolehan = document.getElementById("tablePerolehan"),
    tablePerolehanUlang = document.getElementById("tablePerolehanUlang"),
    tableJumlahSuara = document.getElementById("tableJumlahSuara"),
    tableJumlahSuaraUlang = document.getElementById("tableJumlahSuaraUlang"),
    firstTab = document.getElementById("first-tab"),
    secondPane = document.getElementById("second-pane"),
    secondTab = document.getElementById("second-tab"),
    modalTitle = document.querySelector("#detailTPSLabel"),
    modalTitleUlang = document.querySelector("#detailTPSLabelUlang"),
    catPem = null;
  if (Apps.router.lastRouteResolved().params.wilayah.length <= 13) {
    Apps.firstDropdown.value === "pkwkp"
      ? (catPem = "pkwkp")
      : (catPem = "pkwkk");
    Apps.router.navigate(
      `/${catPem}/${Apps.jenisSuaraDropdown.value}/${paramsTps}`
    );
  }
  if (katPem.value == "pkwkp") {
    modalTitle.textContent = modalTitleUlang.textContent =
      "HASIL HITUNG SUARA PEMILIHAN GUBERNUR & WAKIL GUBERNUR 2020";
  } else {
    let textLabel = null;
    Apps.kabkotaDropdown.innerText.includes("KOTA ")
      ? (textLabel =
          "HASIL HITUNG SUARA PEMILIHAN WALIKOTA DAN WAKIL WALIKOTA 2020")
      : (textLabel =
          "HASIL HITUNG SUARA PEMILIHAN BUPATI DAN WAKIL BUPATI 2020");
    modalTitle.textContent = modalTitleUlang.textContent = textLabel;
  }
  fetch(
    `${baseUrl}/${hhcURL}/${katPem.value}/${paramsProv}/${paramsKab}/${paramsKec}/${paramsKel}/${paramsTps}.json`
  )
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      let dataUSL = data.usl;
      let spanVersi = document.getElementById("detailTPSts"),
        spanVersiUlang = document.getElementById("detailTPStsUlang"),
        txtTitleDetailTPS = document.getElementById("txtTitleDetailTPS"),
        txtTitleDetailTPSUlang = document.getElementById(
          "txtTitleDetailTPSUlang"
        ),
        txtHeaderDetailTPSUlang = document.getElementById(
          "txtHeaderDetailTPSUlang"
        ),
        txtHeaderDetailTPS = document.getElementById("txtHeaderDetailTPS"),
        txtSalinan = document.getElementById("txtSalinan"),
        txtSalinanUlang = document.getElementById("txtSalinanUlang");
      if (dataUSL === "R") {
        makeTableHakPilih(
          tableHakPilih,
          data.pengguna_hak_pilih,
          data.surat_suara_digunakan
        );
        secondTab.classList.add("hidden"),
          secondPane.classList.add("hidden"),
          firstTab.classList.add("hidden"),
          firstTab.click();
      } else {
        if (firstTab.classList.contains("active"))
          firstTab.classList.remove("active");
        secondTab.classList.add("active"), secondTab.click();
        dataUSL === "U"
          ? ((secondTab.innerText = "PENGHITUNGAN SUARA ULANG"),
            (txtTitleDetailTPSUlang.innerText = "HITUNG SUARA ULANG"))
          : ((secondTab.innerText = "PENGHITUNGAN SUARA LANJUTAN"),
            (txtTitleDetailTPSUlang.innerText = "HITUNG SUARA LANJUTAN"));
        makeTableHakPilih(
          tableHakPilih,
          data.pengguna_hak_pilih_old,
          data.surat_suara_digunakan_old
        ),
          makeTableHakPilih(
            tableHakPilihUlang,
            data.pengguna_hak_pilih,
            data.surat_suara_digunakan
          );
        if (
          secondTab.classList.contains("hidden") &&
          secondPane.classList.contains("hidden") &&
          firstTab.classList.contains("hidden")
        )
          secondTab.classList.remove("hidden"),
            secondPane.classList.remove("hidden"),
            firstTab.classList.remove("hidden");
      }

      dataPaslon().then((dataPaslon) => {
        Apps.removeAllComponent(tablePerolehan);
        Apps.removeAllComponent(tablePerolehanUlang);
        let katPemScope = undefined,
          arrChart = [],
          arrChartUlang = [];
        katPem.value == "pkwkp"
          ? (katPemScope = dataPaslon[paramsProv])
          : (katPemScope = dataPaslon[paramsProv][paramsKab]);
        if (dataUSL === "R") {
          btnImage.addEventListener("click", function () {
            makeGalery("gallery-dokumen", "R");
          });
        } else {
          btnImage.addEventListener("click", function () {
            makeGalery("gallery-dokumen", "notR");
          });

          btnImageUlang.addEventListener("click", function () {
            makeGalery("gallery-dokumen-ulang", "notR");
          });
        }
        for (let datas in katPemScope) {
          if (data.chart !== null) {
            arrChartUlang.push({
              name: katPemScope[datas].nama,
              y: data.chart[datas],
            });
          } else {
            arrChartUlang.push({
              name: katPemScope[datas].nama,
              y: 0,
            });
          }

          if (data.chart_old !== null) {
            arrChart.push({
              name: katPemScope[datas].nama,
              y: data.chart_old[datas],
            });
          } else {
            arrChart.push({
              name: katPemScope[datas].nama,
              y: 0,
            });
          }

          let dataSuaraSahUlang = undefined;

          if (dataUSL === "R") {
            calcChart(arrChartUlang, "tps-chart");

            let trUL = document.createElement("tr");
            let thUL = document.createElement("th");
            let tdDUL = document.createElement("td");
            let tdSUL = document.createElement("td");

            thUL.setAttribute("class", "text-center");
            thUL.appendChild(document.createTextNode(datas));
            trUL.appendChild(thUL);

            tdSUL.setAttribute("class", "text-left");
            tdSUL.appendChild(document.createTextNode(katPemScope[datas].nama));
            trUL.appendChild(tdSUL);

            data.chart === null
              ? dataSuaraSahUlang
              : (dataSuaraSahUlang = data.chart[datas]);
            tdDUL.setAttribute("width", 100);
            tdDUL.appendChild(document.createTextNode(dataSuaraSahUlang));
            trUL.appendChild(tdDUL);
            tablePerolehan.appendChild(trUL);

            makeTableJumlahSuara(
              tableJumlahSuara,
              data.suara_sah,
              data.suara_tidak_sah,
              data.suara_total
            );

            if (data.images.length === 0) {
              btnImage.className = "btn btn-secondary";
              btnImage.setAttribute("disabled", "");
              Apps.removesNone(textNotif);
              Apps.addNone(txtSalinan);
              txtSalinan.innerText = "";
            } else {
              btnImage.className = "btn btn-primary";
              btnImage.removeAttribute("disabled", "");
              Apps.addNone(textNotif);
              Apps.removesNone(txtSalinan);
              txtSalinan.innerText = "Salinan Tersedia";
            }
          } else {
            calcChart(arrChartUlang, "tps-chart-ulang"),
              calcChart(arrChart, "tps-chart");

            let tr = document.createElement("tr");
            let th = document.createElement("th");
            let tdD = document.createElement("td");
            let tdS = document.createElement("td");

            th.setAttribute("class", "text-center");
            th.appendChild(document.createTextNode(datas));
            tr.appendChild(th);

            tdS.setAttribute("class", "text-left");
            tdS.appendChild(document.createTextNode(katPemScope[datas].nama));
            tr.appendChild(tdS);

            let dataSuaraSah = "Belum Tersedia",
              dataSuaraSahUlang = "Belum Tersedia";
            data.chart_old === null
              ? dataSuaraSah
              : (dataSuaraSah =
                  data.chart_old[datas] !== null ? data.chart_old[datas] : "-");
            tdD.setAttribute("width", 100);
            tdD.appendChild(document.createTextNode(dataSuaraSah));

            tr.appendChild(tdD);
            tablePerolehan.appendChild(tr);

            let trUL = document.createElement("tr");
            let thUL = document.createElement("th");
            let tdDUL = document.createElement("td");
            let tdSUL = document.createElement("td");

            thUL.setAttribute("class", "text-center");
            thUL.appendChild(document.createTextNode(datas));
            trUL.appendChild(thUL);

            tdSUL.setAttribute("class", "text-left");
            tdSUL.appendChild(document.createTextNode(katPemScope[datas].nama));
            trUL.appendChild(tdSUL);

            data.chart === null
              ? dataSuaraSahUlang
              : (dataSuaraSahUlang = data.chart[datas]);
            tdDUL.setAttribute("width", 100);
            tdDUL.appendChild(document.createTextNode(dataSuaraSahUlang));
            trUL.appendChild(tdDUL);
            tablePerolehanUlang.appendChild(trUL);

            makeTableJumlahSuara(
              tableJumlahSuara,
              data.suara_sah_old,
              data.suara_tidak_sah_old,
              data.suara_total_old
            ),
              makeTableJumlahSuara(
                tableJumlahSuaraUlang,
                data.suara_sah,
                data.suara_tidak_sah,
                data.suara_total
              );

            if (data.images_old.length === 0) {
              btnImage.className = "btn btn-secondary";
              btnImage.setAttribute("disabled", "");
              Apps.removesNone(textNotif);
              Apps.addNone(txtSalinan);
              txtSalinan.innerText = "";
            } else {
              btnImage.className = "btn btn-primary";
              btnImage.removeAttribute("disabled", "");
              Apps.addNone(textNotif);
              Apps.removesNone(txtSalinan);
              txtSalinan.innerText = "Salinan Tersedia";
            }

            if (data.images.length === 0) {
              btnImageUlang.className = "btn btn-secondary";
              btnImageUlang.setAttribute("disabled", "");
              Apps.removesNone(textNotifUlang);
              Apps.addNone(txtSalinanUlang);
              txtSalinanUlang.innerText = "";
            } else {
              btnImageUlang.className = "btn btn-primary";
              btnImageUlang.removeAttribute("disabled", "");
              Apps.addNone(textNotifUlang);
              Apps.removesNone(txtSalinanUlang);
              txtSalinanUlang.innerText = "Salinan Tersedia";
            }

            btnImage.addEventListener("click", function () {
              makeGalery("gallery-dokumen", "notR");
            });

            btnImageUlang.addEventListener("click", function () {
              makeGalery("gallery-dokumen-ulang", "notR");
            });
          }
        }
        spanVersi.innerText = spanVersiUlang.innerText = `Versi : ${data.ts}`;
        txtTitleDetailTPS.innerText = "HITUNG SUARA";
        if (katPem.value == "pkwkp") {
          txtHeaderDetailTPS.innerText = txtHeaderDetailTPSUlang.innerText = `PEMILIHAN GUBERNUR DAN WAKIL GUBERNUR ${Apps.provinsiDropdown.innerText}`;
        } else {
          if (Apps.kabkotaDropdown.innerText.includes("KOTA ")) {
            txtHeaderDetailTPS.innerText = txtHeaderDetailTPSUlang.innerText = `PEMILIHAN WALIKOTA DAN WAKIL WALIKOTA ${Apps.kabkotaDropdown.innerText}`;
          } else {
            txtHeaderDetailTPS.innerText = txtHeaderDetailTPSUlang.innerText = `PEMILIHAN BUPATI DAN WAKIL BUPATI ${Apps.kabkotaDropdown.innerText}`;
          }
        }
      });

      Apps.addNone(chartData), Apps.addNone(tableData);
    })
    .catch((error) => console.log(error));
};

const makeGalery = (compGalery, usl) => {
  let katPem = document.getElementById("walkotgov"),
    paramsProv = document.getElementById("provinsi"),
    paramsKab = document.getElementById("kabkota"),
    paramsKec = document.getElementById("kecamatan"),
    paramsKel = document.getElementById("desa"),
    paramsTps = document.getElementById("tps");

  fetch(
    `${baseUrl}/${hhcURL}/${katPem.value}/${paramsProv.value}/${paramsKab.value}/${paramsKec.value}/${paramsKel.value}/${paramsTps.value}.json`
  )
    .then(async (response) => {
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      let galleryContainer = document.getElementById(compGalery);
      if (usl === "notR") {
        if (compGalery === "gallery-dokumen-ulang") {
          for (let index in data.images) {
            let imgSource = `${baseURLImage}${index}.jpg`;
            let objEmpty = null;
            data.images[index].Hash !== "" &&
            data.images[index].Hash !== null &&
            data.images[index].Hash !== undefined
              ? (objEmpty = `SHA 256: ${data.images[index].Hash}`)
              : (objEmpty = "");

            let a = document.createElement("a");
            a.setAttribute("href", imgSource);
            let img = document.createElement("img");
            img.src = imgSource;
            img.title = objEmpty;
            a.appendChild(img);
            galleryContainer.appendChild(a);
          }
        } else {
          for (let index in data.images_old) {
            let imgSource = `${baseURLImage}${index}.jpg`;
            let objEmpty = null;
            data.images_old[index].Hash !== "" &&
            data.images_old[index].Hash !== null &&
            data.images_old[index].Hash !== undefined
              ? (objEmpty = `SHA 256: ${data.images_old[index].Hash}`)
              : (objEmpty = "");

            let a = document.createElement("a");
            a.setAttribute("href", imgSource);
            let img = document.createElement("img");
            img.src = imgSource;
            img.title = objEmpty;
            a.appendChild(img);
            galleryContainer.appendChild(a);
          }
        }
      } else {
        for (let index in data.images) {
          let imgSource = `${baseURLImage}${index}.jpg`;
          let objEmpty = null;
          data.images[index].Hash !== "" &&
          data.images[index].Hash !== null &&
          data.images[index].Hash !== undefined
            ? (objEmpty = `SHA 256: ${data.images[index].Hash}`)
            : (objEmpty = "");

          let a = document.createElement("a");
          a.setAttribute("href", imgSource);
          let img = document.createElement("img");
          img.src = imgSource;
          img.title = objEmpty;
          a.appendChild(img);
          galleryContainer.appendChild(a);
        }
      }
      let gallery = undefined;
      if (compGalery === "gallery-dokumen-ulang") {
        gallery = new SimpleLightbox(".gallery-dokumen-ulang a", {
          captionClass: "caption-sha-dokumen",
          widthRatio: 0.9,
          heightRatio: 0.9,
          alertErrorMessage:
            "Gambar tidak ditemukan, gambar berikutnya akan dimuat",
        });
      } else {
        gallery = new SimpleLightbox(".gallery-dokumen a", {
          captionClass: "caption-sha-dokumen",
          widthRatio: 0.9,
          heightRatio: 0.9,
          alertErrorMessage:
            "Gambar tidak ditemukan, gambar berikutnya akan dimuat",
        });
      }
      gallery.open();
      gallery.on("closed.simplelightbox", function () {
        Apps.removeAllComponent(galleryContainer);
        gallery.destroy();
      });
    })
    .catch((error) => console.log(error));
};

// chart options
Highcharts.setOptions({
  colors: ["#ffa600", "#ff6361", "#bc5090", "#58508d", "#003f5c"],
  lang: {
    downloadCSV: "Unduh Lampiran (Excel CSV)",
    downloadXLS: "Unduh Lampiran (Excel XLS)",
    downloadJPEG: "Unduh Gambar (JPEG)",
    downloadPNG: "Unduh Gambar (PNG)",
    downloadPDF: "Unduh Lampiran (PDF)",
    viewFullscreen: "Lihat di Layar Penuh",
    printChart: "Cetak",
    exitFullscreen: "Keluar dari layar penuh",
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "printChart",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "separator",
          "downloadPDF",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
});

export const calcChart = (datas, component) => {
  const options = {
    chart: {
      renderTo: component,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    position: {
      spacingLeft: 0,
      marginRight: 0,
    },
    style: {
      width: "100%",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat:
        "{series.name}: <b>{point.percentage:.1f}%</b><br>Total Suara: <b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
        showInLegend: true,
      },
    },
    responsive: true,
    series: [
      {
        name: "Persentase suara",
        data: datas,
      },
    ],
  };
  const graph = Highcharts.chart(component, options);
  graph.update(options);
};
