"use-strict";

let beState = false;
let beReady = false;

export const beLoading = (state) => {
  if (state && beReady) document.getElementById("beLoading").className = "show";
  else if (beState) document.getElementById("beLoading").className = "hide";
  beState = state;
};

document.addEventListener("DOMContentLoaded", () => {
  const be = document.createElement("div");
  be.id = "beLoading";
  be.className = "hide";

  const beCentered = document.createElement("div");
  beCentered.id = "beLoadingCentered";

  const beSpanSpinner = document.createElement("span");
  beSpanSpinner.setAttribute("class", "visually-hidden");
  beSpanSpinner.appendChild(document.createTextNode("Mohon Menunggu"));
  const beSpinner = document.createElement("div");
  beSpinner.setAttribute("class", "spinner-border text-danger");
  beSpinner.setAttribute("role", "status");
  beSpinner.appendChild(beSpanSpinner);

  const beContent = document.createElement("div");
  beContent.id = "beLoadingContent";
  beContent.appendChild(beSpinner);
  beCentered.appendChild(beContent);
  be.appendChild(beCentered);
  document.body.appendChild(be);
  beReady = true;
  beLoading(beState);
});
