"use-strict";

export const inisiateAnalytic = (params) => {
  window.dataLayer = window.dataLayer || [];
  let locationRef = window.location.href;
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-FF8PQZQY3C");

  if (params !== null && params !== undefined)
    gtag("send", "pageview", locationRef.replace("/#/", "/"));
};
